<template>
  <div>
    <b-card>
      <b-skeleton-table
        v-if="loadingCustomViews"
        :rows="1"
        :columns="2"
        :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
      />
      <div
        v-for="customView in allCustomViews"
        v-else
        :key="customView.id"
        class="profile p-1"
      >
        <div class="row">
          <div
            class="leads collapse-title col-10"
          >
            <span class="d-block font-weight-bolder text-nowrap id ">
              {{ customView.name }}
            </span>
          </div>
          <div
            style="float: right"
            class="leads col-2"
          >
            <div
              v-if="customViewsSwitchLoaders[`${customView.id}`]"
              style="width: 75px;"
              class="spinner text-center"
            >
              <b-spinner
                variant="primary"
                small
              />
            </div>
            <vs-switch
              v-else
              :value="customView.enabled"
              disabled
              :style="`background-color: ${!customView.enabled ? '#FF0000' : '#00E265'}`"
              circle
              class="switchs"
              style="float: right"
              @input="updateSwitchState(customView.id, customView.enabled)"
            >
              <span
                slot="on"
                style="font-weight: bold"
              >Disable</span>
              <span
                slot="off"
                style="font-weight: bold"
              >Enable</span>
            </vs-switch>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import {
  BCard,
  BSkeletonTable,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

Vue.use(Vuesax)

export default {
  components: {
    BCard,
    BSkeletonTable,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      allCustomViews: [],
      loadingCustomViews: false,
      customViewsSwitchLoaders: [],
    }
  },
  async mounted() {
    await this.getAllCustomViews()
  },
  methods: {
    getAllCustomViews() {
      this.loadingCustomViews = true
      this.$axios.get('admin/all-custom-views')
        .then(({ data }) => {
          this.allCustomViews = data.data
          this.loadingCustomViews = false
        }).catch(() => this.$swal('Custom Views not found!'))
    },
    async updateSwitchState(id, status) {
      Vue.set(this.customViewsSwitchLoaders, id, true)
      if (status === false) {
        await this.$axios.put(`admin/enable-profile-home-view/${id}`)
          .then(async () => {
            await this.getAllCustomViews()
          }).catch(() => (this.$swal('custom view already exists!')))
      } else {
        await this.$axios.put(`admin/disable-profile-home-view/${id}`)
          .then(async () => {
            await this.getAllCustomViews()
          }).catch(error => ({ error }))
      }
      Vue.set(this.customViewsSwitchLoaders, id, false)
    },
  },
}
</script>
<style scoped>
.profile{
  border: none;
  margin-bottom: 1rem;
  box-shadow: 0px 3px 11.5px rgba(0,0,0,0.06) !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 7px;
}
.leads{
  width: 40%;
}
.switchs {
  width: 50px;
  padding-inline: 40px;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.id{
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
  padding-left: 15px;
}
.buttonlink{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
}
</style>
